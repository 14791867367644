<form [formGroup]="form" appFocusFormError>
  <app-wizard
    [steps]="allSteps"
    [currentStepNumber]="currentStep$ | async"
    [currentForm]="currentForm$ | async"
    [isProcessing]="processing$ | async"
    [hideButtonContainer]="true"
    [wrapStepContainer]="true"
  >
    <app-moving-auction-address
      *appWizardStep="let change"
      formControlName="address"
      [form]="currentForm$ | async"
      [rentedObjects]="rentedObjectsKeyValue"
      [steps]="allSteps"
      [stepIndex]="0"
      [stepChange]="change"
      (onNextStep)="nextStep()"
      (selectedAddressChange)="setSelectedResidentObjectByAddress($event)"
    >
    </app-moving-auction-address>
    <app-moving-auction-data
      *appWizardStep="let change"
      [address]="getAddressFormValue"
      [selectedRentedObject]="selectedRentedObject"
      formControlName="data"
      [form]="currentForm$ | async"
      [steps]="allSteps"
      [stepIndex]="1"
      [stepChange]="change"
      (onNextStep)="nextStep()"
      (onPreviousStep)="previousStep()"
    >
    </app-moving-auction-data>
    <app-moving-auction-contact
      *appWizardStep="let change"
      formControlName="contact"
      [form]="currentForm$ | async"
      [processing]="processing$ | async"
      [steps]="allSteps"
      [stepIndex]="2"
      [stepChange]="change"
      (onComplete)="completeStep()"
      (onPreviousStep)="previousStep()"
    >
    </app-moving-auction-contact>
  </app-wizard>
</form>
